import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import ButtonContained from "../components/ButtonPrimaryContained";
import ButtonOutlined from "../components/ButtonPrimaryOutlined";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TextField from "@mui/material/TextField";
import { Input, InputLabel } from "@mui/material";

export default function CreateForm({
  onClose,
  showCreateModal,
  table,
  action,
  username,
  type,
  hasTitle = true,
  hasDescription = true,
  hasImage = true,
  hasVideo = false,
  hasEmail = false,
  hasPhone = false,
  hasAddress = false,
}) {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [url, setURL] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
    if (selectedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    } else {
    }
  }, [selectedImage]);

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleClose = () => {
    setSelectedImage("");
    setImagePreview("");
    onClose();
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("table", table);
    if (type) {
      formData.append("type", type);
    }
    if (hasTitle && title) {
      formData.append("title", title);
    }
    if (hasDescription && description) {
      formData.append("description", description);
    }
    if (hasVideo && url) {
      formData.append("url", url);
    }
    if (hasImage && selectedImage) {
      formData.append("image", selectedImage);
    }
    if (hasEmail && email) {
      formData.append("email", email);
    }
    if (hasPhone && phone) {
      formData.append("phone", phone);
    }
    if (hasAddress && address) {
      formData.append("address", address);
    }
    formData.append("username", username);
    dispatch(action(formData));

    setImagePreview("");
    setSelectedImage("");
    onClose();
  };

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={showCreateModal}
      onClose={handleClose}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          margin: "auto",
          alignItems: "center",
          gap: "2em",
          maxHeight: "90%",
          overflowY: "auto",
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Create new row in {table}
        </Typography>
        {hasImage && (
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <InputLabel htmlFor="upload-input" style={{ cursor: "pointer" }}>
                <CloudUploadIcon color="primary" />
                <Input
                  id="upload-input"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleImageSelect}
                />
              </InputLabel>
            }
          >
            <Avatar
              alt=""
              src={imagePreview}
              sx={{ width: "100px", height: "100px" }}
            />
          </Badge>
        )}
        {hasTitle && (
          <TextField
            required
            id="outlined-required"
            label="Title"
            onChange={(event) => setTitle(event.target.value)}
          />
        )}
        {hasDescription && (
          <TextField
            required
            id="outlined-multiline-static"
            label="Description"
            multiline
            rows={4}
            onChange={(event) => setDescription(event.target.value)}
          />
        )}
        {hasEmail && (
          <TextField
            required
            id="outlined-required"
            label="Email"
            onChange={(event) => setEmail(event.target.value)}
          />
        )}
        {hasPhone && (
          <TextField
            required
            id="outlined-required"
            label="Phone"
            onChange={(event) => setPhone(event.target.value)}
          />
        )}
        {hasAddress && (
          <TextField
            required
            id="outlined-required"
            label="Address"
            onChange={(event) => setAddress(event.target.value)}
          />
        )}
        {hasVideo && (
          <TextField
            required
            id="outlined-required"
            label="URL"
            onChange={(event) => setURL(event.target.value)}
          />
        )}
        <Box style={{ display: "flex", gap: "1em" }}>
          <ButtonContained
            onClick={handleSubmit}
            label="Submit"
          ></ButtonContained>
          <ButtonOutlined onClick={handleClose} label="Close"></ButtonOutlined>
        </Box>
      </Box>
    </Modal>
  );
}
