import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import ButtonContained from "../components/ButtonPrimaryContained";
import ButtonOutlined from "../components/ButtonPrimaryOutlined";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TextField from "@mui/material/TextField";
import { Input, InputLabel } from "@mui/material";

export default function CreateServiceForm({
  onClose,
  showCreateModal,
  table,
  action,
  username,
}) {
  const dispatch = useDispatch();
  const [titleImage, setTitleImage] = useState("");
  const [titleImagePreview, setTitleImagePreview] = useState("");
  const [featuresImage, setFeaturesImage] = useState("");
  const [featuresImagePreview, setFeaturesImagePreview] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [featuresTitle, setFeaturesTitle] = useState("");
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    if (titleImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setTitleImagePreview(reader.result);
      };
      reader.readAsDataURL(titleImage);
    }
  }, [titleImage]);

  useEffect(() => {
    if (featuresImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFeaturesImagePreview(reader.result);
      };
      reader.readAsDataURL(featuresImage);
    }
  }, [featuresImage]);

  const handleTitleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setTitleImage(file);
    }
  };

  const handleFeaturesImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFeaturesImage(file);
    }
  };

  const handleClose = () => {
    setTitleImage("");
    setTitleImagePreview("");
    setFeaturesImage("");
    setFeaturesImagePreview("");
    onClose();
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("table", table);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("titleImage", titleImage);
    formData.append("featuresImage", featuresImage);
    formData.append("featuresTitle", featuresTitle);
    formData.append("features", JSON.stringify(features));
    formData.append("username", username);
    dispatch(action(formData));

    setTitleImage("");
    setFeaturesImage("");
    setTitleImagePreview("");
    setFeaturesImagePreview("");
    onClose();
  };

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={showCreateModal}
      onClose={handleClose}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          margin: "auto",
          alignItems: "center",
          gap: "2em",
          maxHeight: "90%",
          overflowY: "auto",
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ width: "fit-content" }}
        >
          Create new row in {table}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <InputLabel>Title Image</InputLabel>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <InputLabel
                  htmlFor="title-image-upload"
                  style={{ cursor: "pointer" }}
                >
                  <CloudUploadIcon color="primary" />
                  <Input
                    id="title-image-upload"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleTitleImageSelect}
                  />
                </InputLabel>
              }
            >
              <Avatar
                alt=""
                src={titleImagePreview}
                sx={{ width: "100px", height: "100px" }}
              />
            </Badge>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <InputLabel>Features Image</InputLabel>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <InputLabel
                  htmlFor="features-image-upload"
                  style={{ cursor: "pointer" }}
                >
                  <CloudUploadIcon color="primary" />
                  <Input
                    id="features-image-upload"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFeaturesImageSelect}
                  />
                </InputLabel>
              }
            >
              <Avatar
                alt=""
                src={featuresImagePreview}
                sx={{ width: "100px", height: "100px" }}
              />
            </Badge>
          </Box>
        </Box>
        <TextField
          required
          id="outlined-required"
          label="Title"
          onChange={(event) => setTitle(event.target.value)}
        />
        <TextField
          required
          id="outlined-multiline-static"
          label="Description"
          multiline
          rows={4}
          onChange={(event) => setDescription(event.target.value)}
        />
        <TextField
          required
          id="outlined-required"
          label="Features Title"
          onChange={(event) => setFeaturesTitle(event.target.value)}
        />
        <TextField
          required
          id="outlined-required"
          label="Features"
          onChange={(event) =>
            setFeatures(
              event.target.value.split(",").map((item) => item.trim())
            )
          }
        />
        <Box style={{ display: "flex", gap: "1em" }}>
          <ButtonContained onClick={handleSubmit} label="Submit" />
          <ButtonOutlined onClick={handleClose} label="Close" />
        </Box>
      </Box>
    </Modal>
  );
}
