import React, { useEffect, useState } from "react";
import DynamicTable from "../../components/DynamicTable";
import ShowImageModal from "../../modals/ImageModal";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import ErrorMessage from "../../components/ErrorMessage";
import EditModal from "../../modals/EditModal";
import { getAction, updateAction } from "../../store/actions/home";
import { DecodeUserRole, DecodeUsername } from "../../hooks/userData";
import { Box } from "@mui/material";

export default function OurVisionBanner() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.home.data);
  const loading = useSelector((state) => state.home.loading);
  const error = useSelector((state) => state.home.error);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showEditModal, setEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedTable, setSelectedTable] = useState(false);
  const userRole = DecodeUserRole();
  const username = DecodeUsername();

  useEffect(() => {
    dispatch(getAction());
  }, [dispatch]);

  const filteredData = data?.staticPages?.ourVision?.map((item) => {
    const { createdAt, updatedAt, translations, ...rest } = item;

    const translationObject = translations?.reduce((acc, translation) => {
      acc[translation.fieldName] = translation.translatedValue;
      return acc;
    }, {});

    return { ...rest, ...translationObject };
  });

  return (
    <Box>
      {(userRole === "admin" || userRole === "superadmin") && (
        <>
          {loading ? (
            <Loader />
          ) : error ? (
            <ErrorMessage message={error} />
          ) : (
            <>
              <EditModal
                onClose={() => setEditModal(false)}
                showEditModal={showEditModal}
                table={selectedTable}
                selectedRow={selectedRow}
                action={updateAction}
                username={username}
              />

              <ShowImageModal
                image={selectedImage}
                onClose={() => setShowModal(false)}
                showModal={showModal}
              />

              <DynamicTable
                data={filteredData}
                setSelectedImage={setSelectedImage}
                setShowModal={setShowModal}
                canCreate={false}
                canDelete={false}
                setEditModal={setEditModal}
                setSelectedRow={setSelectedRow}
                table={"Bottom Banner"}
                setSelectedTable={setSelectedTable}
                staticPage={true}
                hasLang={true}
                getAction={getAction}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
}
