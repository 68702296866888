import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import homeReducer from "../slices/homeSlice";
import servicesReducer from "../slices/servicesSlice";
import projectsReducer from "../slices/projectsSlice";
import whatIsNewReducer from "../slices/whatIsNewSlice";
import quotationsReducer from "../slices/quotationsSlice";
import footerReducer from "../slices/footerSlice";
import logsReducer from "../slices/logsSlice";

const store = configureStore({
  reducer: {
    home: homeReducer,
    services: servicesReducer,
    projects: projectsReducer,
    whatIsNew: whatIsNewReducer,
    quotations: quotationsReducer,
    footer: footerReducer,
    logs: logsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunkMiddleware),
  devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
